import angular from "angular";

import AuthenticationDialog from "./AuthenticationDialog/index.js";
import AutoLogout from "./AutoLogout/index.js";
import AvatarMenu from "./AvatarMenu/index.js";
import BuildNotifier from "./BuildNotifier/index.js";
import DailyTrendEventsButton from "./DailyTrendEventsButton/index.js";
import DateAndTime from "./DateAndTime/index.js";
import DisabledMessage from "./DisabledMessage/index.js";
import EditReportWarning from "./EditReportWarning/index.js";
import FacilityNotification from "./FacilityNotification/index.js";
import generatedReportElements from "./generatedReportElements/index.js";
import HighEventLoadDetectedNotification from "./HighEventLoadDetectedNotification/index.js";
import inboxItem from "./inboxItem/index.js";
import itemList from "./itemList/index.js";
import MdnCriteriaTooltip from "./MdnCriteriaTooltip/index.js";
import pagination from "./pagination/index.js";
import PatientDiaryTable from "./PatientDiaryTable/index.js";
import PublishedReportRejectedNotification from "./PublishedReportRejectedNotification/index.js";
import PvcBurden from "./PvcBurden/index.js";
import ReportsPage from "./ReportsPage/index.js";
import ReportUpload from "./ReportUpload/index.js";
import ResetAccount from "./ResetAccount/index.js";
import SearchBar from "./SearchBar/index.js";
import SideBarChip from "./SideBarChip/index.js";
import sideNavigation from "./sideNavigation/index.js";
import sortArrow from "./sortArrow/index.js";
import stripList from "./stripList/index.js";
import StudiesTable from "./StudiesTable/index.js";
import StudyDetails from "./StudiesTable/StudyDetails/index.js";
import ArrhythmiaBurden from "./study-graphs/ArrhythmiaBurden/index.js";
import ArrhythmiaEpisodes from "./study-graphs/ArrhythmiaEpisodes/index.js";
import AtrialFibrillationStats from "./study-graphs/AtrialFibrillationStats/index.js";
import VentricularEctopy from "./study-graphs/VentricularEctopy/index.js";
import SupportPage from "./SupportPage/index.js";
import topNavigation from "./topNavigation/index.js";
import UserClassificationChip from "./UserClassificationChip/index.js";

export default angular.module("app.components", [
  itemList,
  AutoLogout,
  DailyTrendEventsButton,
  DateAndTime,
  pagination,
  sortArrow,
  inboxItem,
  stripList,
  BuildNotifier,
  topNavigation,
  sideNavigation,
  SupportPage,
  StudiesTable,
  StudyDetails,
  generatedReportElements,
  PvcBurden,
  ReportUpload,
  ResetAccount,
  PatientDiaryTable,
  MdnCriteriaTooltip,
  PublishedReportRejectedNotification,
  ArrhythmiaEpisodes,
  ArrhythmiaBurden,
  VentricularEctopy,
  AtrialFibrillationStats,
  EditReportWarning,
  DisabledMessage,
  AuthenticationDialog,
  FacilityNotification,
  AvatarMenu,
  ReportsPage,
  SearchBar,
  SideBarChip,
  UserClassificationChip,
  HighEventLoadDetectedNotification,
]).name;
