/* eslint-env browser */

import angular from "angular";

import logo from "../../../img/br-logo-inbox.svg";

/* @ngInject */
export default class SideNavigationController {
  constructor(
    $mdSidenav,
    Config,
    Authentication,
    $window,
    $scope,
    $rootScope,
    SocketService,
    $state,
    SearchService,
    $mdToast,
    $mdDialog,
    WorkflowsService,
    NotificationService,
    InboxItemSearchParamsService,
    PubsubMessageService,
    InboxItemService,
    $transitions
  ) {
    this._$mdSidenav = $mdSidenav;
    this._Authentication = Authentication;
    this._$window = $window;
    this._$scope = $scope;
    this._$rootScope = $rootScope;
    this._SocketService = SocketService;
    this._$state = $state;
    this._Search = SearchService;
    this._$mdToast = $mdToast;
    this._$mdDialog = $mdDialog;
    this._WorkflowsService = WorkflowsService;
    this._NotificationService = NotificationService;
    this._InboxItemSearchParamsService = InboxItemSearchParamsService;
    this._PubsubMessageService = PubsubMessageService;
    this._InboxItemService = InboxItemService;
    this._$transitions = $transitions;

    this.features = Config.features;
    this.bitRhythmLogo = logo;

    this._$mdToast.hide(document.getElementsByClassName("md-toast-banner"));

    this.triageEnabledForFacility =
      this._WorkflowsService.workflowSettings[this._Authentication.getFacilityId()]?.triageEnabled;

    document.getElementById("mainDocument").style.paddingLeft = "225px";

    // onError is triggered when the page is clicked and already active
    this._$transitions.onError({}, (transitionStates) => {
      const error = transitionStates.error();
      if (this.pageIsSearchable() && error.message === "The transition was ignored") {
        // Clears search text and notifies watchers
        this._Search.searchText = "";
      }
    });

    this._$transitions.onStart({}, () => {
      this._Search.clearSearchText();
    });

    angular.element(this._$window).bind("resize", () => {
      this._$rootScope.$emit("window-resize");
    });
  }

  displayTriageChips() {
    return this.triageEnabledForFacility && !this.isAuthorized(["clinicalStaff", "physician"]);
  }

  displayTriageButton() {
    return (
      this.isAuthorized(["triageTech"]) || // Handle case where triage techs can log in but triage is disabled
      this.displayTriageChips()
    );
  }

  pageIsSearchable() {
    let searchable;

    try {
      searchable = this._$state.current.data.searchable;
    } catch (error) {
      searchable = false;
    }
    return searchable;
  }

  async logOut() {
    await this._Authentication.logOut();
    this._SocketService.onDisconnect(() => this._SocketService.removeConnectionListeners());
    this._SocketService.disconnect();
  }

  /**
   * Checks if the user role is within the allowed roles
   * @param {string[]} allowedRoles
   * @returns {boolean}
   */
  isAuthorized(allowedRoles) {
    return this._Authentication.isInAnyRole(allowedRoles);
  }

  displayIfAuthorized(stateName) {
    let display;
    const state = this._$state.get(stateName);

    try {
      const allowedRoles = state.data.roles;
      display = this.isAuthorized(allowedRoles);
    } catch (error) {
      display = false;
    }

    return display;
  }
}
