import pugFile from "./pagination.pug";

export default {
  bindings: {
    clickedFirstPage: "&",
    clickedPreviousPage: "&",
    clickedNextPage: "&",
    clickedLastPage: "&",
    paginationService: "<",
  },
  controller: "PaginationController",
  template: pugFile(),
};
