import pugFile from "./stripList.pug";

export default {
  controller: "StripListController",
  bindings: {
    item: "=",
    strips: "=",
    includedStrips: "=",
    chartToggles: "=",
  },
  template: pugFile(),
};
