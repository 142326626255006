import angular from "angular";
import uiRouter from "@uirouter/angularjs";

import stripListComponent from "./stripList.component.js";
import StripListController from "./stripList.controller.js";

export default angular
  .module("app.components.stripList", [uiRouter])
  // matches <br-strip-list>
  .component("brStripList", stripListComponent)
  .controller("StripListController", StripListController).name;
