import angular from "angular";

import {angularizeDirective} from "../../../shared/react-in-angular.js";
import ArrhythmiaEpisodes from "./ArrhythmiaEpisodes.jsx";

export default angular.module("app.components.arrhythmiaEpisodes", []).name;

// matches <br-arrhythmia-episodes>
angularizeDirective(
  ArrhythmiaEpisodes,
  "brArrhythmiaEpisodes",
  angular.module("app.components.arrhythmiaEpisodes"),
  {
    id: "=",
    study: "=",
    start: "=",
    end: "=",
    toggles: "=",
  }
);
