/* eslint-env browser */

import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useInterval} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../../axiosClient.js";
import AddProviders from "../../../shared/react/AddProviders.jsx";
import Alert from "../../../shared/react/Alert.jsx";
import TableLoading from "../../../shared/react/TableLoading.jsx";
import DonutChart from "../DonutChart.jsx";

// Unless we can get socket.io or long polling working, fetching the data
// every 5 minutes should keep things from getting "stale"
const DATA_REFRESH_INTERVAL_MS = 300000;

function ArrhythmiaEpisodes({
  // Props
  id,
  study,
  start,
  end,
  toggles,
}) {
  //---------------------------------------------------------------------------
  // The following variables are the prop names that _should be_ if we weren't
  // using pug templates to connect this to angularjs.
  //---------------------------------------------------------------------------
  const reportId = id;
  const studyId = study;
  const startTime = start;
  const endTime = end;
  const chartToggles = toggles;

  //---------------------------------------------------------------------------
  // Error handling state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Fetch data from API
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(true);
  const [arrhythmiaEpisodes, setArrhythmiaEpisodes] = React.useState([]);
  const [patientActivatedCount, setPatientActivatedCount] = React.useState(0);

  const getArrhythmiaEpisodesData = React.useCallback(async () => {
    setLoading(true);

    try {
      const {data: arrhythmiaEpisodesData} = await axios({
        method: "get",
        url: `/reports/arrhythmia-episodes/${studyId}`,
        params: {
          startTime: startTime.toUTC().toISO(),
          endTime: endTime.toUTC().toISO(),
        },
      });

      setArrhythmiaEpisodes(arrhythmiaEpisodesData.events);
      setPatientActivatedCount(arrhythmiaEpisodesData.patientActivatedEvents);
    } catch (err) {
      setError(err.message);
    }

    setLoading(false);
  }, [setError, endTime, startTime, studyId]);

  useInterval(getArrhythmiaEpisodesData, DATA_REFRESH_INTERVAL_MS, loading);

  //---------------------------------------------------------------------------
  // Data formatting
  //---------------------------------------------------------------------------
  const formatter = React.useCallback((payload) => payload.count, []);
  const additionalData = React.useMemo(() => {
    return [{name: "Patient Activated", value: patientActivatedCount}];
  }, [patientActivatedCount]);

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <AddProviders>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />

      {loading && <TableLoading />}

      {!loading && (
        <DonutChart
          title="Arrhythmia Episodes"
          reportId={reportId}
          chartId="arrhythmiaEpisode"
          data={arrhythmiaEpisodes}
          dataKey="percent"
          additionalData={additionalData}
          additionalDataPosition="top"
          legendFormatter={formatter}
          chartToggles={chartToggles}
        />
      )}
    </AddProviders>
  );
}

ArrhythmiaEpisodes.propTypes = {
  id: PropTypes.string.isRequired,
  study: PropTypes.number.isRequired,
  start: PropTypes.object.isRequired,
  end: PropTypes.object.isRequired,
  toggles: PropTypes.object,
};
export default ArrhythmiaEpisodes;
