import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import useArrayReducer from "../components/hooks/useArrayReducer.jsx";

const StudiesContext = React.createContext(null);
const StudiesDispatchContext = React.createContext(null);

export function StudiesProvider({children}) {
  const reducer = useArrayReducer();
  const [studies, dispatch] = React.useReducer(reducer, []);

  return (
    <StudiesContext.Provider value={studies}>
      <StudiesDispatchContext.Provider value={dispatch}>{children}</StudiesDispatchContext.Provider>
    </StudiesContext.Provider>
  );
}
StudiesProvider.propTypes = {
  children: PropTypes.node,
};

export function useStudies() {
  return React.useContext(StudiesContext);
}

export function useStudiesDispatch() {
  return React.useContext(StudiesDispatchContext);
}
