/* @ngInject */
export default class SortArrowController {
  constructor() {
    // Bound to component
    this.direction = null;
    this.currentSort = null;
    this.sortProperty = null;
  }

  get iconName() {
    return this.direction === "ASC" ? "keyboard_arrow_up" : "keyboard_arrow_down";
  }

  get show() {
    return this.currentSort === this.sortProperty;
  }
}
