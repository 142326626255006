/* eslint-env browser */

import React from "react";
import * as d3 from "d3";
import PropTypes from "prop-types";
import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip as ChartTooltip} from "recharts";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import {useTheme} from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import DonutChartLegend from "./DonutChartLegend.jsx";

function DonutChart({
  // Props
  title,
  reportId,
  chartId,
  data,
  dataKey,
  additionalData,
  additionalDataPosition,
  legendFormatter,
  chartToggles,
  children,
}) {
  //---------------------------------------------------------------------------
  // Chart Toggle state management
  //
  // If the chartToggles are not applicable (e.g. on generated reports), set
  // checked to true
  //
  // Note: Move all toggle logic outside of this graph component once
  // report items are converted to React
  //---------------------------------------------------------------------------
  const [checked, setChecked] = React.useState(
    chartToggles?.[chartId] === undefined ? true : chartToggles?.[chartId]
  );
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  // If switch is toggled, update the chartToggles object that is used in the
  // reportItem Angular controller
  React.useEffect(() => {
    if (chartToggles) {
      chartToggles[chartId] = checked;
    }
  }, [chartToggles, chartId, checked]);

  //---------------------------------------------------------------------------
  // Colors state management
  //---------------------------------------------------------------------------
  const theme = useTheme();
  const colors = React.useMemo(() => {
    const grayscale = !checked;
    const range = grayscale ? ["#dbdbdb", "#7a7a7a"] : ["#abe2fc", "#004c8b"];

    return d3
      .scaleLinear()
      .domain([0, data.length - 1])
      .range(range);
  }, [data, checked]);
  const getColor = React.useCallback(
    (value, index) => {
      if (value === "Unclassified") {
        return theme.palette.grey[700];
      }
      return colors(index);
    },
    [theme.palette.grey, colors]
  );
  const textColor = React.useMemo(() => {
    if (!checked) {
      return theme.palette.text.disabled;
    }
    return theme.palette.text.primary;
  }, [checked, theme.palette.text]);

  //---------------------------------------------------------------------------
  // Reference to the legend to determine what the height of the chart container
  // should be
  //---------------------------------------------------------------------------
  const [height, setHeight] = React.useState(225);
  const ref = React.useCallback((node) => {
    if (node !== null) {
      const legendHeight = node.getBoundingClientRect().height;
      if (legendHeight > 225) {
        setHeight(legendHeight);
      }
    }
  }, []);

  //---------------------------------------------------------------------------
  // Data formatting
  //---------------------------------------------------------------------------
  const tooltipFormatter = React.useCallback((value, name, {payload}) => `${payload.percent}%`, []);

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <>
      <Grid container sx={{alignItems: "center"}}>
        <Grid size={2} />
        <Grid size={8}>
          <Typography variant="h6" align="center" sx={{color: textColor}}>
            {title}
          </Typography>
        </Grid>
        <Grid size={2}>
          {chartToggles && (
            <Tooltip title={checked ? "Exclude from Report" : "Include in Report"}>
              <Switch
                sx={{mr: 1.5}}
                color="secondary"
                checked={checked}
                onChange={handleChange}
                id={`${reportId}-${chartId}-chart-toggle`}
              />
            </Tooltip>
          )}
        </Grid>
      </Grid>
      {!!data?.length && (
        <>
          <ResponsiveContainer
            width="100%"
            height={height}
            sx={{margin: "auto"}}
            id={`${chartId}Card-${reportId}`}
          >
            <PieChart id={`${chartId}-${reportId}`}>
              <Pie data={data} dataKey={dataKey} outerRadius={80} innerRadius={20}>
                {data.map((entry, index) => (
                  <Cell key={entry.name} fill={getColor(entry.name, index)} />
                ))}
              </Pie>
              <Legend
                layout="vertical"
                align="right"
                verticalAlign="middle"
                width="45%"
                content={
                  <DonutChartLegend
                    reportId={reportId}
                    chartId={chartId}
                    getColor={getColor}
                    textColor={textColor}
                    formatter={legendFormatter}
                    additionalData={additionalData}
                    additionalDataPosition={additionalDataPosition}
                    ref={ref}
                  />
                }
              />
              <ChartTooltip
                separator=": "
                formatter={tooltipFormatter}
                contentStyle={{borderRadius: 4, fontSize: 14}}
              />
            </PieChart>
          </ResponsiveContainer>

          {children}
        </>
      )}

      {!data?.length && (
        <Box
          sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}
          id={`${chartId}Card-${reportId}`}
        >
          <Typography variant="body2">
            <i>There is no data for this time period</i>
          </Typography>
        </Box>
      )}
    </>
  );
}

DonutChart.propTypes = {
  title: PropTypes.string.isRequired,
  reportId: PropTypes.string,
  chartId: PropTypes.string,
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string.isRequired,
  additionalData: PropTypes.array,
  additionalDataPosition: PropTypes.oneOf(["top", "bottom"]),
  legendFormatter: PropTypes.func.isRequired,
  chartToggles: PropTypes.object,
  children: PropTypes.node,
};
export default DonutChart;
