import angular from "angular";

import inboxItemModule from "../inboxItem/index.js";
import itemListComponent from "./itemList.component.js";
import ItemListController from "./itemList.controller.js";

export default angular
  .module("app.components.itemList", [inboxItemModule])
  // matches <item-list>
  .component("itemList", itemListComponent)
  .controller("ItemListController", ItemListController).name;
