/* eslint-env browser */

import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useInterval} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../../axiosClient.js";
import AddProviders from "../../../shared/react/AddProviders.jsx";
import Alert from "../../../shared/react/Alert.jsx";
import TableLoading from "../../../shared/react/TableLoading.jsx";
import DonutChart from "../DonutChart.jsx";

// Unless we can get socket.io or long polling working, fetching the data
// every 5 minutes should keep things from getting "stale"
const DATA_REFRESH_INTERVAL_MS = 300000;

function VentricularEctopy({
  // Props
  id,
  study,
  start,
  end,
  toggles,
}) {
  //---------------------------------------------------------------------------
  // The following variables are the prop names that _should be_ if we weren't
  // using pug templates to connect this to angularjs.
  //---------------------------------------------------------------------------
  const reportId = id;
  const studyId = study;
  const startTime = start;
  const endTime = end;
  const chartToggles = toggles;

  //---------------------------------------------------------------------------
  // Error handling state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Fetch data from API
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(true);
  const [groupings, setGroupings] = React.useState([]);
  const [percentOfTotal, setPercentOfTotal] = React.useState(null);

  const getVentricularEctopyData = React.useCallback(async () => {
    setLoading(true);

    try {
      const {data: ventricularEctopyData} = await axios({
        method: "get",
        url: `/reports/ventricular-ectopy/${studyId}`,
        params: {
          startTime: startTime.toUTC().toISO(),
          endTime: endTime.toUTC().toISO(),
        },
      });

      setGroupings(ventricularEctopyData.groupings);
      setPercentOfTotal(ventricularEctopyData.percentOfTotal);
    } catch (err) {
      setError(err.message);
    }

    setLoading(false);
  }, [setError, endTime, startTime, studyId]);

  useInterval(getVentricularEctopyData, DATA_REFRESH_INTERVAL_MS, loading);

  //---------------------------------------------------------------------------
  // Data formatting
  //---------------------------------------------------------------------------
  const formatter = React.useCallback((payload) => payload.count, []);
  const additionalData = React.useMemo(() => {
    return [{name: "Percent of Total Beats", value: percentOfTotal ? `${percentOfTotal}%` : "N/A"}];
  }, [percentOfTotal]);

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <AddProviders>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />

      {loading && <TableLoading />}

      <DonutChart
        title="Ventricular Ectopy"
        reportId={reportId}
        chartId="ventricularEctopy"
        data={groupings}
        dataKey="percent"
        additionalData={additionalData}
        additionalDataPosition="top"
        legendFormatter={formatter}
        chartToggles={chartToggles}
      />
    </AddProviders>
  );
}

VentricularEctopy.propTypes = {
  id: PropTypes.string.isRequired,
  study: PropTypes.number.isRequired,
  start: PropTypes.object.isRequired,
  end: PropTypes.object.isRequired,
  toggles: PropTypes.object,
};
export default VentricularEctopy;
