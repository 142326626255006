import angular from "angular";

import {angularizeDirective} from "../../../shared/react-in-angular.js";
import AtrialFibrillationStats from "./AtrialFibrillationStats.jsx";

export default angular.module("app.components.atrialFibrillationStats", []).name;

// matches <br-atrial-fibrillation-stats>
angularizeDirective(
  AtrialFibrillationStats,
  "brAtrialFibrillationStats",
  angular.module("app.components.atrialFibrillationStats"),
  {
    id: "<",
    study: "<",
    start: "<",
    end: "<",
    toggles: "<",
  }
);
