import angular from "angular";

import paginationComponent from "./pagination.component.js";
import PaginationController from "./pagination.controller.js";

export default angular
  .module("app.components.pagination", [])
  // matches <br-pagination>
  .component("brPagination", paginationComponent)
  .controller("PaginationController", PaginationController).name;
