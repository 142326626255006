import angular from "angular";
import uiRouter from "@uirouter/angularjs";

import sideNavigationComponent from "./sideNavigation.component.js";
import SideNavigationController from "./sideNavigation.controller.js";

export default angular
  .module("app.components.sideNavigation", [uiRouter])
  // matches <br-side-navigation>
  .component("brSideNavigation", sideNavigationComponent)
  .controller("SideNavigationController", SideNavigationController).name;
