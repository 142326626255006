import pugFile from "./sortArrow.pug";

export default {
  bindings: {
    direction: "<",
    currentSort: "<",
    sortProperty: "@",
  },
  controller: "SortArrowController",
  template: pugFile(),
};
