/* @ngInject */
export default function CustomizeTheme($mdThemingProvider, $mdInkRippleProvider) {
  const primaryBlue = $mdThemingProvider.extendPalette("light-blue", {
    300: "58a5ef",
    500: "0277bd",
    800: "004c8b",
    A100: "004c8b",
    contrastDefaultColor: "dark",
    contrastDarkColors: undefined,
    contrastLightColors: ["500", "800", "A100"],
  });
  const secondaryBlue = $mdThemingProvider.extendPalette("blue", {
    A100: "80d6ff",
    A200: "42a5f5",
    A400: "0077c2",
    A700: "0077c2",
    contrastDefaultColor: "dark",
    contrastDarkColors: undefined,
    contrastLightColors: ["A200", "A400", "A700"],
  });
  const secondaryGrey = $mdThemingProvider.extendPalette("grey", {
    50: "ffffff",
    A100: "62727b",
    A200: "37474f",
    A400: "102027",
    A700: "102027",
  });
  const warnAndError = $mdThemingProvider.extendPalette("red", {
    300: "c62828",
    500: "c62828",
    800: "ffab00",
    A100: "ffab00",
  });
  $mdThemingProvider.definePalette("primaryBlue", primaryBlue);
  $mdThemingProvider.definePalette("secondaryBlue", secondaryBlue);
  $mdThemingProvider.definePalette("secondaryGrey", secondaryGrey);
  $mdThemingProvider.definePalette("warnAndError", warnAndError);
  $mdThemingProvider
    .theme("default")
    .primaryPalette("primaryBlue", {
      default: "500",
    })
    .accentPalette("secondaryBlue")
    .backgroundPalette("grey", {
      default: "100",
    })
    .warnPalette("warnAndError");
  $mdThemingProvider
    .theme("alternateTheme")
    .primaryPalette("primaryBlue", {
      default: "500",
    })
    .accentPalette("secondaryGrey")
    .backgroundPalette("secondaryGrey", {
      default: "50",
    });
  $mdInkRippleProvider.disableInkRipple();
}
