import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import useArrayReducer from "../components/hooks/useArrayReducer.jsx";
import useJwt from "../components/hooks/useJwt.jsx";

const ReportsContext = React.createContext(null);
const ReportsDispatchContext = React.createContext(null);

export function ReportsProvider({children}) {
  const {username} = useJwt();

  const reducer = useArrayReducer({storageKey: `${username}.reports.filter`, filterBy: "state"});
  const [reports, dispatch] = React.useReducer(reducer, []);

  return (
    <ReportsContext.Provider value={reports}>
      <ReportsDispatchContext.Provider value={dispatch}>{children}</ReportsDispatchContext.Provider>
    </ReportsContext.Provider>
  );
}
ReportsProvider.propTypes = {
  children: PropTypes.node,
};

export function useReports() {
  return React.useContext(ReportsContext);
}

export function useReportsDispatch() {
  return React.useContext(ReportsDispatchContext);
}
