import pugFile from "./itemList.pug";

/**
 * matches <item-list>
 */
export default {
  bindings: {
    searchParams: "<",
    prefilledSearch: "<",
    listType: "@",
  },
  controller: "ItemListController",
  template: pugFile(),
};
