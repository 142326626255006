export default class PaginationController {
  constructor() {
    // bound to component
    this.clickedFirstPage = null;
    this.clickedPreviousPage = null;
    this.clickedNextPage = null;
    this.clickedLastPage = null;
    this.paginationService = null;
  }

  get previousDisabled() {
    return !this.paginationService.isPreviousPageAvailable();
  }

  get nextDisabled() {
    return !this.paginationService.isNextPageAvailable();
  }

  get pageStart() {
    return this.paginationService.getStartingPointForPage();
  }

  get pageEnd() {
    return this.paginationService.getEndingPointForPage();
  }

  get total() {
    return this.paginationService.totalCount;
  }

  get rangeText() {
    return `Showing ${this.pageStart} - ${this.pageEnd} of ${this.total}`;
  }
}
