import angular from "angular";

import sortArrowComponent from "./sortArrow.component.js";
import SortArrowController from "./sortArrow.controller.js";

export default angular
  .module("app.components.sortArrow", [])
  // matches <sort-arrow>
  .component("sortArrow", sortArrowComponent)
  .controller("SortArrowController", SortArrowController).name;
