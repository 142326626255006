/* eslint-env browser */

import React from "react";
import {Duration} from "luxon";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useInterval} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../../axiosClient.js";
import AddProviders from "../../../shared/react/AddProviders.jsx";
import Alert from "../../../shared/react/Alert.jsx";
import TableLoading from "../../../shared/react/TableLoading.jsx";
import DonutChart from "../DonutChart.jsx";

// Unless we can get socket.io or long polling working, fetching the data
// every 5 minutes should keep things from getting "stale"
const DATA_REFRESH_INTERVAL_MS = 300000;

function AtrialFibrillationStats({
  // Props
  id,
  study,
  start,
  end,
  toggles,
}) {
  //---------------------------------------------------------------------------
  // The following variables are the prop names that _should be_ if we weren't
  // using pug templates to connect this to angularjs.
  //---------------------------------------------------------------------------
  const reportId = id;
  const studyId = study;
  const startTime = start;
  const endTime = end;
  const chartToggles = toggles;

  //---------------------------------------------------------------------------
  // Error handling state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Fetch data from API
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(true);
  const [durations, setDurations] = React.useState([]);
  const [highestMedianHeartRate, setHighestMedianHeartRate] = React.useState(null);
  const [lowestMedianHeartRate, setLowestMedianHeartRate] = React.useState(null);
  const [longestAfDuration, setLongestAfDuration] = React.useState(null);

  const getAfStats = React.useCallback(async () => {
    setLoading(true);

    try {
      const {data: ventricularEctopyData} = await axios({
        method: "get",
        url: `/reports/atrial-fibrillation/${studyId}`,
        params: {
          startTime: startTime.toUTC().toISO(),
          endTime: endTime.toUTC().toISO(),
        },
      });

      setDurations(ventricularEctopyData.durations);
      setHighestMedianHeartRate(ventricularEctopyData.highestMedianHeartRate);
      setLowestMedianHeartRate(ventricularEctopyData.lowestMedianHeartRate);
      setLongestAfDuration(ventricularEctopyData.longestAfDuration);
    } catch (err) {
      setError(err.message);
    }

    setLoading(false);
  }, [setError, endTime, startTime, studyId]);

  useInterval(getAfStats, DATA_REFRESH_INTERVAL_MS, loading);

  //---------------------------------------------------------------------------
  // Data formatting
  //---------------------------------------------------------------------------
  const formatter = React.useCallback(
    (payload) => Duration.fromMillis(payload.duration).toFormat("d'd' h'h' m'm' s's'"),
    []
  );
  const additionalData = React.useMemo(() => {
    return [
      {name: "Highest Median HR", value: highestMedianHeartRate ? `${highestMedianHeartRate} bpm` : "N/A"},
      {name: "Lowest Median HR", value: lowestMedianHeartRate ? `${lowestMedianHeartRate} bpm` : "N/A"},
      {
        name: "Longest Episode",
        value: longestAfDuration
          ? `${Duration.fromMillis(longestAfDuration).toFormat("d'd' h'h' m'm' s's'")}`
          : "N/A",
      },
    ];
  }, [highestMedianHeartRate, lowestMedianHeartRate, longestAfDuration]);

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <AddProviders>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />

      {loading && <TableLoading />}

      {!loading && (
        <DonutChart
          title="Atrial Fibrillation Stats"
          reportId={reportId}
          chartId="atrialFibrillationStats"
          data={durations}
          dataKey="percent"
          additionalData={additionalData}
          additionalDataPosition="bottom"
          legendFormatter={formatter}
          chartToggles={chartToggles}
        />
      )}
    </AddProviders>
  );
}

AtrialFibrillationStats.propTypes = {
  id: PropTypes.string.isRequired,
  study: PropTypes.number.isRequired,
  start: PropTypes.object.isRequired,
  end: PropTypes.object.isRequired,
  toggles: PropTypes.object,
};
export default AtrialFibrillationStats;
