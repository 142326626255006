import angular from "angular";

import {angularizeDirective} from "../../../shared/react-in-angular.js";
import ArrhythmiaBurden from "./ArrhythmiaBurden.jsx";

export default angular.module("app.components.arrhythmiaBurden", []).name;

// matches <br-arrhythmia-burden>
angularizeDirective(
  ArrhythmiaBurden,
  "brArrhythmiaBurden",
  angular.module("app.components.arrhythmiaBurden"),
  {
    id: "<",
    study: "<",
    start: "<",
    end: "<",
    toggles: "<",
  }
);
