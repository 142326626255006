import angular from "angular";

import topNavigationComponent from "./topNavigation.component.js";
import TopNavigationController from "./topNavigation.controller.js";

export default angular
  .module("app.components.topNavigation", [])
  // matches <br-top-navigation>
  .component("brTopNavigation", topNavigationComponent)
  .controller("TopNavigationController", TopNavigationController).name;
